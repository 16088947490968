<template>
    <v-md-editor
      :value="contentHtml"
      mode="preview"
    ></v-md-editor>
</template>

<script>
  export default {
    props: ['contentHtml'],
  }
</script>

<style>
  .ql-size-large {
    font-size: 1.5rem !important;
  }
  .ql-size-huge {
    font-size: 2.5em !important;
  }
  .ql-size-small {
    font-size: 0.75em !important;
  }
</style>